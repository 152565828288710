<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <el-container>
      <el-header class="home-header">
        <div class="logo-title">
            <div>校园生活管理系统</div>

            <div @click="isCollapse = !isCollapse" >
              <span :class="`iconfont ${isCollapse ? 'icon-zhankai' : 'icon-shouqi'}`"></span>
            </div>
        </div>
      </el-header>
      <el-container>
        <el-aside style="width: auto;">
          <el-menu default-active="1" :router="true" :collapse-transition="true" :unique-opened="true" class="el-menu-vertical" :style="`height: ${containerHeight - 60 + 'px'}`"
           background-color="#545c64" text-color="#fff" active-text-color="#ffd04b" @open="handleOpen" @close="handleClose" :collapse="isCollapse">
             <el-menu-item index="/home/admin">
              <i class="iconfont icon-guanliyuan"></i>
              <span slot="title">管理员</span>
            </el-menu-item>
             <el-submenu index="config">
              <template slot="title">
                <i class="iconfont icon-shangpinguanli"></i>
                <span slot="title" style="padding-left: 4px">配置</span>
              </template>
                <el-menu-item index="/home/config">小程序配置</el-menu-item>
                <el-menu-item index="/home/serviceConfig">自营业务配置</el-menu-item>
            </el-submenu>
            <el-menu-item index="/home/rider">
              <i class="iconfont icon-qishou"></i>
              <span slot="title">骑手</span>
            </el-menu-item>
            <el-menu-item index="/home/goods">
              <i class="iconfont icon-shangpinguanli"></i>
              <span slot="title">商品</span>
            </el-menu-item>
            <el-submenu index="store">
              <template slot="title">
                <i class="iconfont icon-shangpinguanli"></i>
                <span slot="title" style="padding-left: 4px">商户</span>
              </template>
                <el-menu-item index="/home/store">商户列表</el-menu-item>
                <el-menu-item index="/home/store/storeCounter">商户营业统计</el-menu-item>
                <el-menu-item index="/home/store/user">商户管理员</el-menu-item>
                <el-menu-item index="/home/store/goodsType">商户商品类别</el-menu-item>
            </el-submenu>
            <el-menu-item index="/home/coupon">
              <i class="iconfont icon-youhuiquan"></i>
              <span slot="title">优惠券</span>
            </el-menu-item>
            <el-submenu index="asd">
              <template slot="title">
                <i class="iconfont icon-dianhuabu"></i>
                <span slot="title" style="padding-left: 4px">校园电话簿</span>
              </template>
                <el-menu-item index="/home/phonebook/list">校园电话簿</el-menu-item>
                <el-menu-item index="/home/phonebook/type">电话簿分类</el-menu-item>
            </el-submenu>
            <el-submenu index="school">
              <template slot="title">
                <i class="iconfont icon-dianhuabu"></i>
                <span slot="title" style="padding-left: 4px">学校管理</span>
              </template>
                <el-menu-item index="/home/school">学校管理</el-menu-item>
                <el-menu-item index="/home/school/pickupPoint">取货点管理</el-menu-item>
            </el-submenu>
            <el-menu-item index="/home/order">
              <i class="iconfont icon-dingdan"></i>
              <span slot="title">订单列表</span>
            </el-menu-item>
            <el-menu-item index="/home/feedbook">
              <i class="iconfont icon-fankuiyijian"></i>
              <span slot="title">反馈意见</span>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <el-container>
          <el-main :style="`height: ${containerHeight - 134 + 'px'}`" class="no-scroll">
             <router-view/>
          </el-main>
          <el-footer>
            <div style="display: flex; align-items: center; justify-content: center; height: 100%;">
                <span>备案号：</span>
                <a href="https://beian.miit.gov.cn/"  target="_blank">闽ICP备2022013365号-1</a>
            </div>
          </el-footer>
        </el-container>
      </el-container>
    </el-container>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'Home',
    components: {
    },
    data () {
        return {
            isCollapse: false,
            containerHeight: 0
        }
    },
    mounted () {
        this.containerHeight = document.body.clientHeight
        console.log(this.containerHeight)
    },
    methods: {
        handleOpen (key, keyPath) {
            console.log(key, keyPath)
        },
        handleClose (key, keyPath) {
            console.log(key, keyPath)
        }
    }
}
</script>

<style lang="scss">
  .home{
    background-color: #f0f2f5;

    .home-header{
      background-color: #ffffff;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 1;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);

      .logo-title{
          display: flex;
          align-items: center;
          height: 100%;

          >div:first-child{
            flex-shrink: 0;
            width: 200px;
            font-size: 20px;
            font-weight: 600;
            text-align: center;
          }

          .iconfont{
            font-size: 24px;
          }
      }
    }

    .el-menu-vertical:not(.el-menu--collapse) {
      width: 200px;
      min-height: 400px;
    }

    .el-main{
      padding: 0;
      margin: 14px 26px 0;
      background-color: #ffffff;
      overflow-y: scroll;
    }

    .el-menu{
      .iconfont{
        font-size: 20px;
        width: 24px;
        display: inline-block;
      }
    }
    .el-menu-item{
      >span{
        display: inline-block;
        padding-left: 4px;
      }
    }

    .el-table {
      th{
        background-color: #f0f2f5 !important;
      }
    }
  }
</style>
